
.header-component {
    min-width: 1400px;
    // min-height: 362px;
    min-height: 100px;
    // background: url(../assets/img/headerbg.jpg);
    background-size: 100% 100%;
    position: relative;
    .top-header {
        width: 1200px;
        height: 100px;
        margin: 0 auto;
        padding-top: 11px;
        line-height: 100px;
        display: flex;
        justify-content: flex-start;
        justify-content: space-between;

        .img-wrap {
            padding-top: 11px;
            img {
                width: 275px; //223px;
                height: 77px;
            }
        }

        .btn-header {
            color: #fff;

            .login {
                margin-left: 22px;
            }
            span {
                cursor: pointer;
            }
        }

        .nav-fr .name {
            display: inline-block;
            margin-left: 4px;
            color: #cd0600;
        }
        .nav-fr .name:before {
            margin-top: 15px;
            content: '';
            float: left;
            width: 26px;
            height: 26px;
        }
        .nav-fr .name.person.personshort {
            width: 130px;
        }
        .nav-fr .name.person {
            // width: 255px;
        }
        // .nav-fr .name.person:before {
        //     background: url('../assets/img/icon_user.png') no-repeat;
        //     background-size: cover;
        // }
        .nav-fr .name.book:before {
            background: url('../assets/img/icon_book.png') no-repeat;
            background-size: cover;
        }
        .nav-fr .name a {
            margin-right: 0;
            font-weight: 500;
            color: #cd0600;
        }
        .nav-fr span.welcome {
            color: #cd0600;
            cursor: auto;
            font-weight: 500;
        }
        .name .login_btn {
            display: flex;
            a.login-a {
                display: block;
                max-width: 220px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .name span {
            margin: 0 5px;
        }

        .welcome-header {
            color: #404040;
            span {
                color: #cd0600;
                cursor: pointer;
            }
        }
        .top-right-header {
            span {
                cursor: pointer;
            }
        }
    }
    .main-header {
        width: 1200px;
        margin: 0 auto;
        .title {
            // padding-top: 230px;
            padding-top: 266px;
            text-align: center;
            margin-bottom: 150px;
            img {
                // width: 907px;
                // height: 248px;
                width: 1008px;
                height: 107px;
            }
        }
        .introduction {
            color: #fff;
            position: absolute;
            bottom: 44px;
            width: 1200px;
            h6 {
                font-size: 32px;
                font-weight: bold;
                line-height: 32px;
                padding-bottom: 39px;
                position: relative;
            }
            h6::after {
                content: '';
                width: 28px;
                height: 6px;
                background: #c30d23;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            div {
                margin-top: 10px;
                margin-bottom: 25px;
                font-size: 24px;
                font-weight: 400;
            }
            p {
                font-family: Source Han Serif CN;
                font-size: 16px;
                font-weight: 500;
                line-height: 23px;
                color: #fff;
            }
        }
    }
}
.bigBg {
    width: 100%;
    height: 960px;
    // height: 1080px;
    background: url(../assets/img/annual-metting/bg-index.jpg);
    background-size: 100% 100%;
    .top-header {
        .nav-fr .name {
            display: inline-block;
            margin-left: 4px;
            color: #fff;
        }
        .nav-fr .name a {
            margin-right: 0;
            font-weight: 500;
            color: #fff;
        }
        .nav-fr span.welcome {
            color: #fff;
            cursor: auto;
            font-weight: 500;
        }
        .welcome-header {
            color: #404040;
            span {
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
