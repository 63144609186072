
.header-oa-component {
  width: 100%;
  height: 400px;
  background: url(../assets/img/aj/AJ-banner.png);
  background-size: 100% 100%;
  float: left;
  color: #fff;
  .header-oa-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-logo {
      width: 937px;
      margin-top: 108px;
    }
    .title-h5 {
      font-size: 67px;
      color: #fff;
      margin-top: 92px;
    }
    .search-input {
      width: 1016px;
      height: 96px;
      padding: 8px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 40px;
      margin-bottom: 12px;
    }
    .search-wrap {
      display: flex;
      .go-search {
        /*width: 280px;*/
        height: 40px;
        line-height: 40px;
        background: rgba(248, 250, 251, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        padding: 0 16px;
        span {
          font-size: 20px;
          color: #c30d23;
          text-decoration: underline;
          font-weight: bold;
          text-underline-offset: 3px;
          cursor: pointer;
        }
        img {
          margin-left: 8px;
          width: 16px;
          justify-self: 16px;
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }


    .search-border-wrap {
      .search-icon {
        font-size: 26px;
        line-height: 0.46rem;
        color: #fff;
        cursor: pointer;
      }
      .search-btn {
        padding: 0 52px;
        border-radius: 0%;
        float: right;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}
