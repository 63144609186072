
.aj-collected-papers {
    .aj-collected {
        display: flex;
        .left-img {
            .img-wrap {
                padding: 20px 35px;
                border: 1px solid rgba(153, 153, 153, 0.4);
                margin-right: 8px;
                img {
                    display: block;
                    width: 180px;
                    height: auto;
                    height: 256px;
                }
            }
        }
        
        .info-wrap {
            width: 940px;
            ul {
                li {
                    display: flex;
                    margin-bottom: 4px;
                    h4 {
                        width: 140px;
                        margin-right: 4px;
                        color: #FFFFFF;
                        font-size: 16px;
                        background: #C30D23;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    p {
                        width: 324px;
                        font-size: 16px;
                        color: #0D1020;
                        line-height: 20px;
                        padding: 15px 14px;
                        box-sizing: border-box;
                        border: 1px solid rgba(153,153,153,0.4);
                        text-align: left;
                    
                    }
                    p:nth-child(2) {
                        margin-right: 4px;
                    }
                }
                li.publisher {
                    p {
                        flex: 1;
                        margin-right: 0;
                    }
                }
                li.abstract {
                    p {
                        flex: 1;
                        margin-right: 0;
                        height: 300px;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                li.contributed-articles {
                    .contributed-articles-msg {
                        flex: 1;
                        margin-right: 0;
                        height: 704px;
                        border: 1px solid rgba(153, 153, 153, 0.4);
                        padding: 15px 14px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
